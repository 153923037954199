import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Phone from "../images/svg/phone";
import Mark from "../images/svg/mark";
import Mail from "../images/svg/mail";

import logoBackground from "../images/svg/logo-background.svg";

const Container = styled.section`
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: none;
`;

const Error = styled.h1`
  font-size: 5rem;
  font-weight: bold;
  margin: 7rem auto 0;
  padding: 1rem 1.125rem;
  border-bottom: 2px solid ${props => props.theme.colors.primary};
`;

const Content = styled.div`
  text-align: center;
  padding: 1.5rem 1rem;
  background-color: #ffffff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-top: 2.5rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 275px;
`;

const Title = styled.h2`
  font-size: 1rem;
  margin: 0 auto 4rem;
  font-weight: inherit;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: -0.75rem;
    left: 50%;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    width: 55px;
    transform: translateX(-50%);
  }
`;

const ContactOptions = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ContactOption = styled.a`
  display: block;
`;

const Icon = styled.div`
  svg {
    max-height: 45px;
  }
`;

const Label = styled.p``;

const LogoBackground = styled.img`
  position: absolute;
  left: 0;
  top: 10%;
  z-index: -1;
  max-height: 100vh;
  max-width: 90vw;
  filter: saturate(0);
  opacity: 0.25;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    bottom: -15%;
    top: unset;
  }
`;

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contact {
            address {
              value
              link
            }
            mail {
              value
            }
            phone {
              value
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="404: Nie znaleziono" />
      <Container>
        <Error>404</Error>
        <Content>
          <Title>Skontaktuj się bezpośrednio</Title>
          <ContactOptions>
            <ContactOption
              href={`tel:${data.site.siteMetadata.contact.phone.value}`}
            >
              <Icon>
                <Phone />
              </Icon>
              <Label>{data.site.siteMetadata.contact.phone.value}</Label>
            </ContactOption>
            <ContactOption
              href={data.site.siteMetadata.contact.address.link}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <Icon>
                <Mark />
              </Icon>
              <Label>{data.site.siteMetadata.contact.address.value}</Label>
            </ContactOption>
            <ContactOption
              href={`mailto:${data.site.siteMetadata.contact.mail.value}`}
            >
              <Icon>
                <Mail />
              </Icon>
              <Label>{data.site.siteMetadata.contact.mail.value}</Label>
            </ContactOption>
          </ContactOptions>
        </Content>
        <LogoBackground src={logoBackground} alt="" aria-hidden={true} />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
